import React from "react";
import logo from "../../assets/menemenLogo.png";

const Navbar = () => {
  return (
    <div className="fixed top-0 left-0 w-full bg-white bg-opacity-75 z-10">
      <div className=" p-2">
        <div className="flex flex-row items-center justify-between mx-16">
          <div className="flex flex-row items-center">
            <img src={logo} className="w-24"/>
            <p className="ml-3 text-4xl">Menemen Belediyesi</p>
          </div>
          <div className="flex flex-row gap-x-8 items-center">
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Anasayfa
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Menemen
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Kurumsal
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Hizmetlerimiz
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Projeler
            </div>
            <div className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Etkinlikler
            </div>
            <a href="https://www.menemen.bel.tr/ImgListe/179/basinda-biz" className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer">
              Basında Biz
            </a>
            <a
              href="https://www.menemen.bel.tr/menemen-iletisim"
              className="text-base text-customNavbarTextColor font-bold text-lg cursor-pointer"
            >
              İletişim
            </a>
            <div className="flex w-96 rounded bg-white">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Size nasıl yardımcı olabiliriz?"
                className="w-full border-2 bg-transparent p-4 text-gray-900 rounded-md"
              />
              <button className="m-2 rounded bg-[#9cbf95] px-5 py-2 text-[#ffffff] absolute left-left91 top-top5">
                Ara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
